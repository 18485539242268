div#unified-sidebar-component {
  background-color: transparent;
  min-width: 100%;
}
#unified-sidebar-component a {
  font-family: Arial, sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
}
#unified-sidebar-component .sidebar {
  background-color: rgba(255, 165, 0, 1) !important;
  color: #ffffff !important;
  margin-bottom: 3px !important;
  border: 1px solid rgba(255, 165, 0, 1) !important;
  border-radius: 0 !important;
}

#unified-sidebar-component a.sidebar:not(:first-of-type) {
  margin-top: 3px !important;
}

#unified-sidebar-component .sidebar:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
  text-decoration: none;
  border: 1px solid #ffffff !important;
}
#unified-sidebar-component .sidebar.active {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: none !important;
}

#unified-sidebar-component .list-group-item {
  padding: 12px 10px;
}
