body {
  background-color: #f1f2f7 !important;
}
#unified-header-component .clr {
  clear: both;
}
#unified-header-component .header a.header-logo {
  margin-top: 3px;
  width: 124px;
}
#unified-header-component .header a.header-logo img {
  max-width: 100%;
}
#unified-header-component .header-navbar {
  margin-left: 15px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  margin-bottom: 0px;
}
#unified-header-component .header-navbar li {
  float: left;
  list-style: none;
}
#unified-header-component .header-navbar a {
  display: block;
  padding: 23px 15px;
  color: #666;
  font-family: Arial, sans-serif;
  text-decoration: none;
}
#unified-header-component .header-navbar .active a,
#unified-header-component .header-navbar a:hover,
#unified-header-component .header-navbar a:focus,
#unified-header-component .header-navbar a:active {
  color: #fff;
  background-color: #58c9f9;
  text-decoration: none;
}
#unified-header-component .header-dropdown-frame .profile-dropdown li > a {
  color: #000 !important;
}
#unified-header-component
  .header-dropdown-frame
  .profile-dropdown
  li:last-child {
  margin-bottom: 0px;
}
#unified-header-component .header-dropdown-frame .profile-dropdown li:hover {
  background-color: #58c9f9;
}
#unified-header-component .header-dropdown-frame .profile-dropdown li:hover > a,
#unified-header-component .header-dropdown-frame .profile-dropdown li > a:hover,
#unified-header-component .header-dropdown-frame .profile-dropdown li:hover {
  color: #fff !important;
}
#unified-header-component .header-dropdown div.user-dropdown {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 20ms ease-in-out;
  cursor: pointer;
  padding: 4px;
  height: 40px;
  width: 40px;
}
#unified-header-component .header-dropdown div.user-dropdown:hover {
  background-color: rgba(60, 64, 67, 0.08);
}
#unified-header-component .header-dropdown img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
#unified-header-component li.log-out-button:hover span {
  color: #fff !important;
}
#unified-header-component li.log-out-button {
  cursor: pointer;
}
#unified-header-component .header-dropdown div a {
  text-decoration: none;
}
#unified-header-component .header-dropdown-frame {
  position: absolute;
  width: 180px;
  right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 59.5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.176) !important;
}
#unified-header-component .header-dropdown-frame ul {
  list-style: none inside none;
}
#unified-header-component .header-dropdown-frame ul > li {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
#unified-header-component .header-dropdown-frame ul > li:first-child {
  border-top: 0px;
}
#unified-header-component .header-dropdown span {
  display: block;
  font-size: 13px;
  word-break: break-word;
}
#unified-header-component .header-dropdown-frame ul a {
  display: block;
  font-size: 13px;
}
#unified-header-component .header-dropdown-frame ul a p {
  color: #a4abbb;
  font-size: 13px;
  font-weight: 400;
}
#unified-header-component .header-dropdown-frame ul a p:first-child {
  margin-bottom: 6px;
}
#unified-header-component .header-dropdown-frame ul > li.active,
#unified-header-component .header-dropdown-frame ul > li.active p,
#unified-header-component .header-dropdown-frame ul > li.active a,
#unified-header-component .header-dropdown-frame ul > li.active span {
  color: #fff !important;
  background-color: #58c9f9;
}
#unified-header-component .header-dropdown-frame ul > li.active,
#unified-header-component
  .header-dropdown-frame
  ul
  > li.active
  span.logout:hover {
  color: #fff !important;
  background-color: #58c9f9;
}
#unified-header-component .header-dropdown-frame ul > li.in-active,
#unified-header-component .header-dropdown-frame ul > li.in-active p {
  color: #666;
  background-color: none;
}
#unified-header-component .menu-icon {
  color: #666;
  margin-right: 18px;
  cursor: pointer;
}
#unified-header-component .menu-icon:hover {
  opacity: 0.8;
  transition: opacity 20ms ease-in-out;
}
#unified-header-component .header-navbar.mobile {
  display: block !important;
  z-index: 1999;
  margin-top: 0;
  position: absolute;
  width: 200px;
  left: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 59.5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.176) !important;
}
#unified-header-component .decoration-none {
  text-decoration: none;
}
#unified-header-component .text-black {
  color: black !important;
}
#unified-header-component .middle {
  float: left;
  padding: 6px 0;
}
#unified-header-component .glyphicon.glyphicon-briefcase {
  font-size: 17px !important;
}
#unified-header-component .fa {
  font-size: 22px !important;
}
#unified-header-component .username {
  float: left;
}
#unified-header-component .nav-menu-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: space-between;
}
@media screen and (min-width: 1024px) {
  #unified-header-component .menu-icon {
    display: none;
  }
  #unified-header-component .mobile-menu {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  #unified-header-component .header-navbar.desktop {
    display: none;
  }
  #unified-header-component .header-navbar {
    width: 100%;
    margin-top: 19px;
    margin-left: 0;
    background-color: #fff;
    padding: 0;
  }
  #unified-header-component .header-navbar li {
    float: none;
  }
  #unified-header-component .header-navbar li + li {
    margin-top: 1px;
  }
  #unified-header-component .header-navbar a {
    padding: 15px;
  }
  #unified-header-component .nav-menu-wrapper {
    justify-content: flex-end;
  }
}
#unified-header-component .fs-16 {
  font-size: 16px !important;
}
#unified-header-component .header-dropdown-listing {
  padding: 0;
  margin-bottom: 0px;
}
#unified-header-component #clr {
  float: left;
  background-color: white;
  border: 1px solid white;
}
@media screen and (max-width: 340px) {
  #unified-header-component .header-dropdown-frame {
    width: 225px !important;
  }
}
#unified-header-component .header-dropdown-menu-container {
  display: flex;
  align-items: center;
}
#unified-header-component .tenant-dropdown-wrapper {
  margin-right: 3.5vw;
  display: flex;
}
#unified-header-component *:hover {
  text-decoration: none;
}
#unified-header-component .header .row:before,
#unified-header-component .header .row:after {
  display: none !important;
  content: none !important;
}

#unified-header-component
  .profile-dropdown
  > li:not(:first-child)
  > *:first-child {
  padding: 17px 15px;
}

#unified-header-component .skeleton-loader-nav-text {
  padding: 23px 15px;
}

#unified-header-component .feedBackDropDownMenu {
  left: auto !important;
  right: 0;
  width: 250px;
  top: 43px !important;
}

#unified-header-component .feedBackTextArea {
  outline: none;
  box-shadow: none;
  font-size: 13px;
  min-height: 127px;
}

#unified-header-component .feedBackAttachmentInput {
  width: 100%;
  margin-right: 12px;
  font-size: 13px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #ced4da;
  border-radius: 4px;
}

#unified-header-component .feedBackDropInputMessage {
  line-height: 20px;
}

#unified-header-component .alertMessageText {
  line-height: 20px;
}

#unified-header-component .disabledFeedBackAttachmentInput {
  background: #e9ecef;
}

#unified-header-component .feedbackFormTitle {
  color: #66ccff;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 8px;
}

#unified-header-component .feedBackFooter {
  border-top: 1px solid #e3e3e3;
  padding-top: 8px;
}

#unified-header-component .cursorNotAllowed {
  cursor: not-allowed;
}

#unified-header-component .circularLoader {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 14px;
}
#unified-header-component .circularLoader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: circle-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
#unified-header-component .circularLoader div:nth-child(1) {
  animation-delay: -0.45s;
}
#unified-header-component .circularLoader div:nth-child(2) {
  animation-delay: -0.3s;
}
#unified-header-component .circularLoader div:nth-child(3) {
  animation-delay: -0.15s;
}

#unified-header-component .feedbackThankDescription {
  font-size: 13px;
  line-height: 18px
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
